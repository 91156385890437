<template>
    <div class="authorization-page onboarding">
        <div class="form">
            <router-link @click="reload" :to="{name: 'Home'}" class="logo">
                <img :src="require('@/assets/img/logo.png')" class="img-fluid" alt="EURECBROKER">
            </router-link>
            <component :is="currentComponent"
                       @next="step+=1"
                       @previous="step-=1"
            />
        </div>
        <div class="side">
            <img :src="require('@/assets/img/background-authorization-page.png')" :alt="$t('RecBroker authorization image')" />
            <div class="circle black"></div>
            <div class="circle blue"></div>
        </div>
    </div>
</template>

<script>
import ClientStep_1 from "@/components/onboarding/ClientStep_1";
import ClientStep_2 from "@/components/onboarding/ClientStep_2";

export default {
    name: "ClientOnboarding",
    data() {
        return {
            step: 1,
        }
    },
    computed: {
        currentComponent() {
            return `ClientStep_${this.step}`
        }
    },
    components: {
        ClientStep_1,
        ClientStep_2
    }
}
</script>