<template>
    <div class="onboarding-step step-wide">
        <h1>{{ $t("Welcome") }}</h1>
        <p class="step-intro">{{ $t("How would you like to proceed?") }}</p>
        <form>
            <div class="step-actions d-flex justify-content-center">
                <router-link @click="delete $store.getters.user.status" :to="{ name: 'Vacancies' }" class="btn btn-secondary">{{ $t('Create first vacancy') }}</router-link>
                <router-link @click="delete $store.getters.user.status" :to="{ name: 'Candidates' }" class="btn btn-secondary">{{ $t("Search for candidates") }}</router-link>
            </div>
            
            <button class="btn back-btn" @click="$emit('previous')">
                {{ $t("Go back") }}
                <svg xmlns="http://www.w3.org/2000/svg" width="19.829" height="21.451" viewBox="0 0 19.829 21.451">
                    <g>
                        <g>
                            <path d="M6.118 1.032L2.8 4.349h8.477a8.6 8.6 0 0 1 8.552 8.551 8.653 8.653 0 0 1-8.551 8.551H1.917v-1.475h9.362a7.175 7.175 0 0 0 7.076-7.076 7.126 7.126 0 0 0-7.077-7.077H2.8L6.118 9.14l-1.032 1.032L0 5.086 5.086 0z" transform="translate(164) translate(-164)"/>
                        </g>
                    </g>
                </svg>
            </button>
        </form>
    </div>
</template>

<script>
export default {
    name: "ClientStep_2",
}
</script>

<style scoped>

</style>