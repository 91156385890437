<template>
    <div class="onboarding-step">
        <h1>{{ $t("Welcome") }}</h1>
        <p class="step-intro">{{ $t("Let's start with some basic information.") }}</p>
        <form v-if="company">
            <div class="form-group">
                <label class="control-label">{{ $t("What the name of your company?") }}</label>
                <input v-model="company.name" :class="['form-control', {'has-error': errors['name']}]" :placeholder="$t('Enter company name')">
                <FormErrors v-if="errors['name']" :errors="errors['name']"  />
            </div>

            <div class="form-group">
                <label class="control-label">{{ $t("Registration number") }}</label>
                <input v-model="company.company_details.reg_nr" :class="['form-control', {'has-error': errors['company_details.reg_nr']}]" :placeholder="$t('Enter registration number')">
                <FormErrors v-if="errors['company_details.reg_nr']" :errors="errors['company_details.reg_nr']"  />
            </div>

            <div class="form-group">
                <label class="control-label">{{ $t("VAT number") }}</label>
                <input v-model="company.company_details.tax_nr" :class="['form-control', {'has-error': errors['company_details.tax_nr']}]" :placeholder="$t('Enter VAT number')">
                <FormErrors v-if="errors['company_details.tax_nr']" :errors="errors['company_details.tax_nr']"  />
            </div>

            <div class="form-group ">
                <label class="control-label">{{ $t("In what industry is your business?") }}</label>
                
                <Multiselect
                    v-model="company.industry_id"
                    :options="industries"
                    :placeholder="$t('Select industry')"
                    :class="['form-control', {'has-error': errors['industry_id']}]"
                    :searchable="true"
                />

                <FormErrors v-if="errors['industry_id']" :errors="errors['industry_id']"  />
            </div>
            
            <button @click="next" type="button" class="btn btn-primary" :disabled="!(company.name && company.industry_id) || disabled">
                {{ $t("Next") }}
            </button>
        </form>
    </div>
</template>

<script>
import {userService} from "@/services/userService";
import FormErrors from "@/components/widgets/FormErrors";
import Multiselect from '@vueform/multiselect'

export default {
    name: "ClientStep_1",
    data() {
        return {
            company: {
                company_details: {}
            },
        }
    },
    computed: {
        currentComponent() {
            return `ClientStep_${this.step}`
        },
        industries() {
            return this.$store.getters.industries;
        }
    },
    created() {
        userService.getCompany().then(response => this.company = response.data.data)
    },
    methods: {
        next() {
            this.disabled = true
            userService.updateCompany(this.company)
                .then(() => this.$emit('next'))
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)

        }
    },
    components: {
        FormErrors,
        Multiselect
    },
}
</script>